import {Injectable} from '@angular/core';
import {UtilService} from './util.service';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {JwtHelperService} from '@auth0/angular-jwt';
import {Token} from '../models/token';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private readonly uri: string;

  constructor(private utilService: UtilService, private http: HttpClient, private router: Router) {
    this.uri = `${this.utilService.getUrlApi()}/v1/login`;
  }

  login(login: string, senha: string): Observable<any> {
    const input = {
      username: login,
      password: senha,
    };

    return this.http.post(this.uri, input);
  }

  logout(): void {
    localStorage.clear();

    this.router.navigate(['/login']);
  }

  setToken(token: string): void {
    localStorage.setItem('token', token);
  }

  getToken(): string {
    return localStorage.getItem('token');
  }

  setNomeUsuario(nome: string): void {
    localStorage.setItem('nome', nome);
  }

  getNomeUsuario(): string {
    return localStorage.getItem('nome');
  }

  getDadosToken(): Token|null {
    const helper = new JwtHelperService();

    if (this.getToken() === null || this.getToken() === undefined || this.getToken() === '') {
      return null;
    }

    return helper.decodeToken(this.getToken());
  }
}
