import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';

const LIMITE_PADRAO = 50;

@Injectable({
  providedIn: 'root'
})
export class UtilService {
  private readonly uri: string;
  private readonly eventSourceUri: string;

  constructor() {
    this.uri = environment.urlApi;
    this.eventSourceUri = environment.eventSourceUrl;
  }

  getUrlApi(): string {
    return this.uri;
  }

  getEventSourceUri(): string {
    return this.eventSourceUri;
  }

  setCounters(peopleIn: number, peopleOut: number, limite?: number): any {
    const entrada = peopleIn;
    const saida = peopleOut;

    const quantidadeAux = entrada - saida;

    const quantidade = quantidadeAux < 0 ? 0 : quantidadeAux;
    const quantidadeEntraram = entrada;

    const podeEntrar = limite === undefined || limite === null ? (LIMITE_PADRAO - quantidade) : (limite - quantidade);

    const quantidadePodeEntrar = podeEntrar < 0 ? 0 : podeEntrar;

    return {
      entrada,
      saida,
      quantidade,
      quantidadeReal: quantidadeAux,
      quantidadeEntraram,
      quantidadePodeEntrar,
      quantidadePodeEntrarReal: podeEntrar,
    };
  }

  getLimitePadrao(): number {
    return LIMITE_PADRAO;
  }

  tocarAlerta(): void {
    const audio = new Audio('./assets/sounds/alert-2.mp3');

    const promise = audio.play();

    if (promise !== undefined) {
      promise
        .catch(err => {
          // Não permite tocar alerta
        })
        .then(() => {
          // Tocar alerta
        });
    }
  }
}
